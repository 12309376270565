import { keysIn } from 'lodash-es';
import { ignoreBlankValues } from './ignoreBlankValues';

function getStableObjectValue(_key: string, value: object): object {
	// TODO: this should handle static getters on objects

	const keys = keysIn(value);
	keys.sort();

	// Filter out any values that are functions
	const filteredKeys = keys.filter((key) => typeof value[key] !== 'function');

	const sortedObject = filteredKeys.reduce((sorted, key) => {
		sorted[key] = value[key];
		return sorted;
	}, {});

	return sortedObject;
}

export function getStableJson(_key: string, value: unknown): unknown {
	// Ensure keys are sorted in objects
	if (value instanceof Object && !Array.isArray(value)) {
		return getStableObjectValue(_key, value);
	}

	return ignoreBlankValues(_key, value);
}
