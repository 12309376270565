'use client';
import { usePathname } from 'next/navigation';
import { normalizePath } from './normalizePath';

export function isSamePathname(pathA: string, pathB: string): boolean {
	const normalizedA = normalizePath(pathA);
	const normalizedB = normalizePath(pathB);
	return normalizedA === normalizedB;
}

export function useIsNotCurrentPathname(path: string): boolean {
	const currentPathname = usePathname();
	if (!currentPathname || !path) {
		return false;
	}

	return !isSamePathname(path, currentPathname);
}
