import { ErrorResponse } from '@dte/otw/azure-functions/src/common/interfaces/Error';
import { stringifyJSON } from '@dte/otw/azure-functions/src/common/util/objects/stringifyJSON';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { secondsToMilliseconds } from 'date-fns';
import { redirect } from 'next/navigation';
import { getTokenExpiration, getTokenUser } from '../../../contexts/TokenContext/isGoodToken';
import { environmentConfig } from '../environment';
import { logError, logInfo } from '../logging/writeLog';
import { fetchWithRetry } from '../network/fetch/fetchWithRetry';
import { getResponseBody } from './getResponseBody';

function getRequestInit(token: string, method = 'get', body = undefined): RequestInit {
	// Stringify JSON body if needed
	let bodyString: string;
	if (body !== undefined) {
		if (typeof body === 'string') {
			bodyString = body;
		} else {
			bodyString = stringifyJSON(body);
		}
	}

	const requestInit: RequestInit = {
		headers: {
			Accept: 'application/json',
			'Ocp-Apim-Subscription-Key': environmentConfig.apiSubscriptionKey,
			Authorization: `Bearer ${token}`,
		},
		method: method,
		mode: 'cors',
		credentials: 'omit',
		body: bodyString,
		keepalive: false,

		signal: AbortSignal.timeout(secondsToMilliseconds(40)),
	};

	if (bodyString) {
		requestInit.headers['Content-Type'] = 'application/json';
	}

	// Add trace header for non-prod environments
	if (!environmentConfig.isProd) {
		requestInit.headers['Ocp-Apim-Trace'] = true;
	}

	return requestInit;
}

async function handleResponse<T>(appInsights: ReactPlugin, response: Response, token: string) {
	if (!response) {
		logError(appInsights, 'Error: empty response');
		return undefined;
	}

	const responseBody = await getResponseBody<T>(appInsights, response);
	if (response.ok) {
		return responseBody;
	}

	if (!response.ok) {
		const errorBody = responseBody as ErrorResponse;
		handleErrorResponse(appInsights, response, errorBody, token);
		return errorBody;
	}

	return responseBody;
}

function handleErrorResponse(appInsights: ReactPlugin, response: Response, body: ErrorResponse, token: string): void {
	// Check for 401 response
	let errorMessage = checkNotAuthenticated(appInsights, response, body, token);
	if (errorMessage) {
		throw new Error(errorMessage);
	}

	// User doesn't have access
	errorMessage = checkNotAuthorized(appInsights, response, body, token);
	if (errorMessage) {
		throw new Error(errorMessage);
	}
}

function checkNotAuthenticated(
	appInsights: ReactPlugin,
	response: Response,
	body: ErrorResponse,
	token: string,
): string {
	if (response.status !== 401) {
		return undefined;
	}

	let errorMessage = handleBadSubscriptionKey(appInsights, response, body);
	if (errorMessage) {
		return errorMessage;
	}

	errorMessage = handleTokenExpired(appInsights, response, body, token);
	if (errorMessage) {
		return errorMessage;
	}

	return undefined;
}

function checkNotAuthorized(appInsights: ReactPlugin, response: Response, body: ErrorResponse, _token: string): string {
	// Not a 401
	if (response.status !== 403) {
		return undefined;
	}

	const code = body?.error?.code;

	alert("Your account doesn't have the correct roles for access to this application");
	logError(appInsights, 'Unauthorized, error code: ', { code: code });

	return 'Not Authorized';
}

function handleTokenExpired(appInsights: ReactPlugin, _response: Response, body: ErrorResponse, token: string): string {
	const code = body?.error?.code;

	let message: string;

	// TODO: need to be looking at the refreshOn from the authentication result here

	// We expect to see token expired errors
	if (['TOKEN_EXPIRED'].includes(code)) {
		// TODO: try making a silent attempt to acquire a new token
		const user = getTokenUser(token);
		const expiryDate = getTokenExpiration(token);
		message = `Token error: [ ${code} ], user: [ ${user} ], token expiration: [ ${expiryDate?.toISOString()} ]`;
		logInfo(appInsights, message);
	}

	// We shouldn't be seeing these types of errors
	if (['TOKEN_NOT_FOUND', 'TOKEN_INVALID'].includes(code)) {
		const user = getTokenUser(token);
		const expiryDate = getTokenExpiration(token);
		message = `Token error: [ ${code} ], user: [ ${user} ], token expiration: [ ${expiryDate?.toISOString()} ]`;
		logError(appInsights, message);
	}

	if (message) {
		redirectToLogin();
	}

	return undefined;
}

function redirectToLogin(): void {
	if (typeof window === 'undefined') {
		return;
	}

	// TODO: add a parameter to the URL to prevent infinite loops
	window.location.reload();
}

function handleBadSubscriptionKey(appInsights: ReactPlugin, _response: Response, body: ErrorResponse): string {
	let badSubscriptionKey = false;

	const code = body?.error?.code;

	if (['KEY_INVALID', 'KEY_NOT_FOUND'].includes(code)) {
		badSubscriptionKey = true;
	}

	if (!environmentConfig.apiSubscriptionKey) {
		badSubscriptionKey = true;
	}

	if (badSubscriptionKey) {
		if (!environmentConfig.isProd) {
			// Redirect to test page to re-enter subscription key
			redirect('/debug/test?invalidKey=true');
		}

		// Should never get here as long as app config is correct
		const message = `Bad subscription key: [ ${environmentConfig.apiSubscriptionKey} ]`;
		logError(appInsights, message);
		return message;
	}

	return undefined;
}

export async function authenticatedFetch<T>(
	appInsights: ReactPlugin,
	getToken: () => Promise<string>,
	url: string,
	method = 'get',
	body = undefined,
) {
	const token = await getToken();
	if (!token) {
		return undefined;
	}

	if (!url) {
		return undefined;
	}

	const requestInit = getRequestInit(token, method, body);
	const requestInfo: RequestInfo = url;

	const fetchResponse = await fetchWithRetry(requestInfo, requestInit);
	const responseBody = await handleResponse<T>(appInsights, fetchResponse, token);
	return responseBody;
}
