import { filterEmpty } from '../lists/filterEmpty';
import { normalizeValue } from './normalizeValue';

export function safeJoin(parts: string[], separator: string = ' '): string {
	if (!parts?.length) {
		return undefined;
	}

	let filtered = parts?.map(normalizeValue);
	filtered = filterEmpty(filtered);
	if (!filtered?.length) {
		return undefined;
	}

	const joined = filtered.join(separator);
	return normalizeValue(joined);
}
