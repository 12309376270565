'use client';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { isSameParams } from '../app/(client)/(authenticated)/premises/(premiseId)/reportProblem/isSameParams';
import { isSamePathname } from '../app/(client)/(authenticated)/premises/(premiseId)/reportProblem/isSamePathname';
import { useHasChanged } from './useHasChanged';

export function usePathChanged(trigger: () => void) {
	const pathname = usePathname();
	const pathnameChanged = useHasChanged(pathname, isSamePathname);

	const searchParams = useSearchParams();
	const searchParamsChanged = useHasChanged(searchParams, isSameParams);

	const needsTrigger = pathnameChanged || searchParamsChanged;

	// Do something when we navigate
	useEffect(() => {
		if (!needsTrigger) {
			return;
		}

		trigger();
	}, [needsTrigger, trigger]);
}
