import { normalizeValue } from '@dte/otw/azure-functions/src/common/util/strings/normalizeValue';
import { useMemo, useRef } from 'react';
import { v4 as uuidv4, v5 } from 'uuid';

const stableIdNamespace = '93592934-2d69-4f5f-857c-bd24d74ef2d2';

export function getStableId(id?: string): string {
	const normalized = normalizeValue(id) || '';
	return v5(normalized, stableIdNamespace);
}

/**
 * Make sure we don't repeatedly generate new ids if we don't have a control id
 * @param id
 * @returns
 */
export function useStableUniqueId(id?: string): string {
	const backupRef = useRef(uuidv4());

	const stableId = useMemo(() => {
		// Use the default if we don't have an id
		const normalizedId = normalizeValue(id);
		if (!normalizedId) {
			return backupRef.current;
		}

		// Use a v5 uuid if we do
		return getStableId(normalizedId);
	}, [id]);

	return stableId;
}
