'use client';

import { HTMLAttributes, ReactNode, useCallback } from 'react';

interface FocusableContainerProps extends HTMLAttributes<HTMLDivElement> {
	onBlur: () => void;
	onFocus: () => void;
}

export function FocusableContainer({ children, onBlur, onFocus, ...props }: FocusableContainerProps): ReactNode {
	const handleBlur = useCallback(
		(event: React.FocusEvent) => {
			const currentTarget = event.currentTarget;

			// Give browser time to focus the next element
			requestAnimationFrame(() => {
				// Check if the new focused element is a child of the original container
				if (!currentTarget.contains(document.activeElement)) {
					onBlur();
				}
			});
		},
		[onBlur],
	);

	const handleFocus = useCallback(
		(_event: React.FocusEvent) => {
			onFocus();
		},
		[onFocus],
	);

	return (
		<div {...props} onBlur={handleBlur} onFocus={handleFocus} tabIndex={-1}>
			{children}
		</div>
	);
}
