'use client';
import { CountSummary, CountType } from '@dte/otw/azure-functions/src/common/interfaces/containers/config/Count';
import { ReactNode, useState } from 'react';
import AuthenticatedJsonContent from '../../../app/(client)/(authenticated)/content/jsonGet/template';
import { formatCount } from '../formatCount';
import { useCountSummaryURL } from './getCountSummaryURL';

export function BriefCountDetails(): ReactNode {
	const [countSummary, setCountSummary] = useState<CountSummary>();
	const counts = countSummary?.counts || {};

	const url = useCountSummaryURL();

	let content = <>&nbsp;</>;

	const outageCount = counts[CountType.DisplayOutages];
	if (outageCount !== undefined) {
		content = <>{formatCount(outageCount)}</>;
	}

	return (
		<AuthenticatedJsonContent url={url} setData={setCountSummary}>
			{content}
		</AuthenticatedJsonContent>
	);
}
