import { environmentConfig } from '../../../../../common/util/environment';

export function getJobStatusURL(jobId: string): URL {
	if (!jobId) {
		return undefined;
	}

	const url = new URL(`${environmentConfig.apiBase}/authenticated/data/jobs/${jobId}`);
	return url;
}
