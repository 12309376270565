import { AppConfig } from '@dte/otw/azure-functions/src/common/interfaces/containers/config/AppConfig';
import { ReactNode, useState } from 'react';
import AuthenticatedJsonContent from '../../app/(client)/(authenticated)/content/jsonGet/template';
import { getAppConfigURL } from '../../hooks/api/getAppConfigURL';

export function BriefModeDetails(): ReactNode {
	const appConfigURL = getAppConfigURL();
	const [appConfig, setAppConfig] = useState<AppConfig>();

	// Display the details of the mode in settings or the current one
	const settings = appConfig?.settings;
	const mode = settings?.mode;
	const modeDetails = appConfig?.modesLookup[mode];

	return (
		<AuthenticatedJsonContent url={appConfigURL} setData={setAppConfig}>
			{modeDetails?.description || <>&nbsp;</>}
		</AuthenticatedJsonContent>
	);
}
