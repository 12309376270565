import { flattenDeep } from 'lodash-es';
import { isEmpty } from '../objects/isEmpty';

export type MergeableList<T> = Parameters<typeof flattenDeep<T>>[0];
export type MergeInput<T> = T | MergeableList<T>;

export function getArray<T = unknown>(values: MergeInput<T>): T[] {
	if (values === undefined) {
		return [];
	}

	// We got an array
	if (Array.isArray(values)) {
		return flattenDeep<T>(values);
	}

	// We got a single value
	const singleValue = values as T;
	return flattenDeep<T>([singleValue]);
}

export function mergeArrays<T = unknown>(...arrays: MergeInput<T>[]): T[] {
	const merged = arrays.reduce<T[]>((acc, current) => {
		const additionalValues = getArray(current);

		// Nothing to add, return the accumulator unchanged
		if (isEmpty(additionalValues)) {
			return acc;
		}

		// Accumulator was empty, just return the rest
		if (isEmpty(acc)) {
			return additionalValues;
		}

		// Combine the lists
		return acc.concat(additionalValues);
	}, []);

	return merged;
}
