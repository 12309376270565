import { normalizeValue } from '@dte/otw/azure-functions/src/common/util/strings/normalizeValue';
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

type Validator = (value: string) => boolean;

function isValidString(validator: Validator, value: string): boolean {
	if (value === undefined) {
		return false;
	}

	// No validator defined, so we assume it's valid
	if (typeof validator !== 'function') {
		return true;
	}

	return true;
}

function getValidString(value: string, validator?: Validator, defaultValue?: string): string {
	let normalized = normalizeValue(value);

	if (['undefined', 'null'].includes(normalized)) {
		normalized = undefined;
	}

	if (!isValidString(validator, normalized)) {
		return defaultValue;
	}

	return normalized;
}

export function useValidatedStringQueryParam(name: string, validator?: Validator, defaultValue?: string): string {
	const searchParams = useSearchParams();

	const validatedValue = useMemo(() => {
		const currentValue = searchParams.get(name);
		return getValidString(currentValue, validator, defaultValue);
	}, [defaultValue, name, searchParams, validator]);

	return validatedValue;
}
