'use client';

import { faBug } from '@fortawesome/free-solid-svg-icons';
import { ReactNode, useEffect } from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { logException, logWarning } from '../../common/util/logging/writeLog';
import { useAppInsights } from '../AppInsightsWrapper/AppInsightsWrapper';
import { Icon } from '../Spinner/Icon';
import ContentWithDefault from '../contentWithDefault/page';
import styles from './page.module.scss';

export interface ErrorProps {
	tooltipContent?: ReactNode;
	error: Error & { digest?: string };
	reset?: () => void;
}

function DefaultErrorContent(): ReactNode {
	return <>Something went wrong</>;
}

export default function CommonError(props: ErrorProps): ReactNode {
	const appInsights = useAppInsights();

	const error = props.error;
	const reset = props.reset;

	useEffect(() => {
		if (error) {
			// Log the error to an error reporting service
			logException(appInsights, error);
		}
	}, [error, appInsights]);

	let attemptReset: () => void;
	if (typeof reset === 'function') {
		attemptReset = () => {
			// Attempt to recover by trying to re-render the segment
			logWarning(appInsights, 'Attempting to reload component');
			reset();
		};
	}

	const renderTooltip = (tooltipProps: object) => {
		return (
			<Popover className={styles.errorTooltip} {...tooltipProps}>
				<Popover.Header>Error</Popover.Header>
				<Popover.Body>
					<ContentWithDefault content={props.tooltipContent} defaultContent={<DefaultErrorContent />} />
				</Popover.Body>
			</Popover>
		);
	};

	// TODO: add tooltip here

	return (
		<section className={styles.main}>
			<Row className="iconRow">
				<Col className="text-center">
					<OverlayTrigger placement="bottom" flip={true} overlay={renderTooltip} delay={50}>
						<Icon
							className="errorIcon"
							icon={faBug}
							aria-label="Error"
							size={'2x'}
							onClick={attemptReset}
						/>
					</OverlayTrigger>
				</Col>
			</Row>
		</section>
	);
}
