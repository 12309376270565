'use client';

import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import { environmentConfig } from '../../common/util/environment';
import dteLogo from './dteLogo.svg';
import MenuButton from './menu/page';
import styles from './page.module.scss';
import SearchBar from './search/page';

export default function Header(): ReactNode {
	const classNames = ['header'];
	if (!environmentConfig.isProd) {
		classNames.push('nonProd');
	}

	return (
		<header className={styles.main}>
			<div className={classNames.join(' ')}>
				<Row className="g-0">
					<Col xs={'auto'} className="menuColumn">
						<MenuButton />
					</Col>
					<Col xs={'auto'} className="logoColumn">
						<Link href="/" className="logo" prefetch={false}>
							<Image priority={true} src={dteLogo} alt="DTE" />
						</Link>
					</Col>
					<Col xs={'auto'}>
						<h1>Outage</h1>
					</Col>
					<Col className="spacerColumn" md={'auto'} />
					<Col className="searchColumn">
						<SearchBar />
					</Col>
				</Row>
			</div>
		</header>
	);
}
