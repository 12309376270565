import Link from 'next/link';
import { LinkProps } from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { PropsWithChildren, ReactNode, Suspense, useEffect, useState } from 'react';

export type ConditionalLinkProps = LinkProps & PropsWithChildren;

// TODO: this should take advantage of our common logic for checking same pathname / params

export function useCurrentURL(): string {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	const [currentURL, setCurrentURL] = useState<string>();

	useEffect(() => {
		let newURL = `${pathname}`;
		if (!newURL.endsWith('/')) {
			newURL += '/';
		}
		const params = searchParams?.toString();
		if (params) {
			newURL += `?${params}`;
		}
		setCurrentURL(newURL);
	}, [pathname, searchParams]);

	return currentURL;
}

function ConditionalLinkContent(props: ConditionalLinkProps): ReactNode {
	const currentURL = useCurrentURL();
	const href = props.href;

	// We're already here, no need to link to anything
	if (href === currentURL) {
		return props.children;
	}

	return <Link {...props} prefetch={false} />;
}

export default function ConditionalLink(props: ConditionalLinkProps): ReactNode {
	// Wrap in Suspense due ot use of useSearchParams
	// https://nextjs.org/docs/app/api-reference/functions/use-search-params
	return (
		<Suspense>
			<ConditionalLinkContent {...props} />
		</Suspense>
	);
}
