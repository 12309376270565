import {
	AccountInfo,
	AuthenticationResult,
	BrowserAuthError,
	IPublicClientApplication,
	InteractionRequiredAuthError,
	InteractionStatus,
	InteractionType,
	SilentRequest,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { isAfter } from '@dte/otw/azure-functions/src/common/util/dates/compare/isAfter';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { addMinutes, differenceInSeconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { getLoginMethod, getRedirectRequest } from '../../common/util/employeeAuthConfig';
import { logError, logException, logInfo, logWarning } from '../../common/util/logging/writeLog';
import { useAppInsights } from '../../components/AppInsightsWrapper/AppInsightsWrapper';
import { getGoodToken, getTokenExpiration } from './isGoodToken';
import { useMsalAccount } from './useMsalAccount';

export function useAuthenticationResult(): [AuthenticationResult] {
	const appInsights = useAppInsights();

	// Attemp silent login first
	const { instance, inProgress } = useMsal();
	const account = useMsalAccount();
	const [result, setResult] = useState<AuthenticationResult>();

	// TODO: can we use SWR to abstract some of this?
	useEffect(() => {
		if (inProgress !== InteractionStatus.None) {
			// Login in progress
			return;
		}

		if (!account) {
			// No account yet
			return;
		}

		if (!appInsights) {
			// Wait till app insights is loaded
			return;
		}

		const fetcher = async () => {
			const newResult = await singletonFetchAuthenticationResult(appInsights, instance, account);
			setResult(newResult);
		};
		void fetcher();
	}, [account, inProgress, instance, appInsights]);

	return [result];
}

export async function fetchToken(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	account: AccountInfo,
): Promise<string> {
	try {
		const result = await singletonFetchAuthenticationResult(appInsights, instance, account);
		const token = getGoodToken(result?.idToken);
		if (result && !token) {
			logError(appInsights, "Got token but it wasn't valid");
		}
		return token;
	} catch (_error) {
		logError(appInsights, 'Error getting token');
	}
}

// Keep track of the current promise so that we don't call fetch method more than once at a time
let fetchInProgress: Promise<AuthenticationResult> = null;

function tokenExpiresSoon(appInsights: ReactPlugin, token: string): boolean {
	const expiryDate = getTokenExpiration(token);
	if (!expiryDate) {
		logInfo(appInsights, 'Token is missing expiration');
		return true;
	}

	const currentDate = new Date();
	if (isAfter(currentDate, expiryDate)) {
		const seconds = Math.abs(differenceInSeconds(currentDate, expiryDate));
		logInfo(appInsights, `Token expiry: [ ${seconds}s ago ]`);
		return true;
	}

	const thresholdDate = addMinutes(currentDate, 10);
	if (isAfter(thresholdDate, expiryDate)) {
		const seconds = Math.abs(differenceInSeconds(thresholdDate, expiryDate));
		logInfo(appInsights, `Token expiry: [ ${seconds}s from now ]`);
		return true;
	}

	return false;
}

export async function singletonFetchAuthenticationResult(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	account: AccountInfo,
	forceRefresh?: boolean,
	forceManualLogin?: boolean,
): Promise<AuthenticationResult> {
	// Need to make sure that this doesn't get hit multiple times at once
	if (fetchInProgress !== null) {
		return fetchInProgress;
	}

	let result: AuthenticationResult;

	// Start fetching token
	try {
		fetchInProgress = fetchAuthenticationResult(appInsights, instance, account, forceRefresh, forceManualLogin);

		// Need to await the result so that we don't clear the cached promise until after the fetch is complete
		result = await fetchInProgress;
	} catch (error) {
		logException(appInsights, error);
	} finally {
		// Clear promise after we've gotten the result
		fetchInProgress = null;
	}

	return result;
}

// TODO: logic is somewhat convoluted because MSAL doesn't properly check for expiry of id tokens
// See:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4206
export async function fetchAuthenticationResult(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	account: AccountInfo,
	forceRefresh?: boolean,
	forceManualLogin?: boolean,
): Promise<AuthenticationResult> {
	let useForceRefresh = forceRefresh;

	// Set refresh initially based on the age of the current cached
	let needsRefresh = tokenExpiresSoon(appInsights, account?.idToken);
	if (needsRefresh) {
		useForceRefresh = true;
	}

	const tokenRequest: SilentRequest = {
		scopes: ['openid'],
		account: account,
		forceRefresh: useForceRefresh,
	};

	let result: AuthenticationResult;

	try {
		result = await handleLogin(appInsights, instance, tokenRequest, forceManualLogin);
	} catch (error) {
		// If we're not already trying a manual login
		if (!forceManualLogin) {
			return handleLoginError(appInsights, instance, account, error, forceManualLogin);
		}
		logError(appInsights, 'Manual attempt to retrieve token failed due to error');
		logException(appInsights, error);
	}

	// Make sure the result contains a token that isn't about to expire
	needsRefresh = tokenExpiresSoon(appInsights, result?.idToken);
	if (needsRefresh) {
		// Result isn't valid
		result = undefined;

		// Fall back on force refresh if needed
		if (!useForceRefresh) {
			logWarning(appInsights, 'Try again to acquire token, this time forcing a manual login');
			return fetchAuthenticationResult(appInsights, instance, account, true, forceManualLogin);
		}

		// Fall back on manual login next
		if (!forceManualLogin) {
			logWarning(appInsights, 'Manual attempt to retrieve token failed, token was undefined');
			return fetchAuthenticationResult(appInsights, instance, account, true, true);
		}

		logError(appInsights, 'Unable to retrive token from either force refresh or manual login');
	}

	return result;
}
export async function handleLoginError(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	account: AccountInfo,
	error: Error,
	forceManualLogin?: boolean,
): Promise<AuthenticationResult> {
	let retryError = false;
	let forceManualLoginOnRetry = forceManualLogin;

	if (error instanceof InteractionRequiredAuthError) {
		logWarning(appInsights, 'Interaction required - do a manual login');
		retryError = true;
		forceManualLoginOnRetry = true;
	}

	if (error instanceof BrowserAuthError) {
		if (error?.errorMessage?.includes('timeout')) {
			logWarning(appInsights, 'Login timed out, retry');
			retryError = true;
		}
	}

	// If we weren't already trying a manual login, go ahead and try again
	if (retryError) {
		return singletonFetchAuthenticationResult(appInsights, instance, account, true, forceManualLoginOnRetry);
	}

	return undefined;
}
export async function handleLogin(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
	tokenRequest: SilentRequest,
	forceManualLogin?: boolean,
): Promise<AuthenticationResult> {
	let result: AuthenticationResult;

	if (forceManualLogin) {
		// Use a manual process like popup or redirect to get the token
		result = await manualLogin(appInsights, instance);
	} else {
		result = await instance.acquireTokenSilent(tokenRequest);
	}

	return result;
}
export async function manualLogin(
	appInsights: ReactPlugin,
	instance: IPublicClientApplication,
): Promise<AuthenticationResult> {
	const loginMethod = getLoginMethod();

	const request = getRedirectRequest();

	logInfo(appInsights, `Attempt to acquire token manually, method: [ ${loginMethod} ]`);

	if (loginMethod === InteractionType.Redirect) {
		await instance.acquireTokenRedirect(request);
		return undefined;
	}

	if (loginMethod === InteractionType.Popup) {
		return instance.acquireTokenPopup(request);
	}

	return undefined;
}
