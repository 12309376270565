import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { ReactNode, useId } from 'react';

/**
 * Wrapped class for icons
 *
 * @param props
 * @returns
 */
export function Icon(props: FontAwesomeIconProps): ReactNode {
	// Generate a stable id
	const titleId = useId();

	// TODO: Remove this when https://github.com/FortAwesome/Font-Awesome/issues/19348 is resolved
	// This is a workaround to fix hydration issues with FontAwesome + Next.js when using the title tag
	let title = null;
	const ariaProps: Partial<FontAwesomeIconProps> = {};
	if (props.title) {
		// Create a separate element visible only to screen readers for the title
		title = <span className="sr-only">{props.title}</span>;
		ariaProps.title = undefined;
		ariaProps['aria-labelledby'] = titleId;
	}

	return (
		<>
			{title}
			<FontAwesomeIcon {...props} {...ariaProps} />
		</>
	);
}
