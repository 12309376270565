import { isBlankValue } from './isBlankValue';

export function isEmptyObject(value: object, depth: number = 0): boolean {
	if (depth > 20) {
		return false;
	}

	if (isBlankValue(value)) {
		return true;
	}

	// Look at object properties, since keys may not be
	const properties = Object.getOwnPropertyNames(value);
	for (const property of properties) {
		const notEmpty = isNotEmpty(value[property], depth + 1);
		if (notEmpty) {
			return false;
		}
	}

	// If we didn't find anything on the object itself, check the prototype
	const valuePrototype = Object.getPrototypeOf(value);

	// Follow the chain until we get to the base object
	if (valuePrototype !== Object.prototype) {
		return isEmptyObject(valuePrototype, depth + 1);
	}

	// If we get this far, assume the object isn't empty
	return false;
}

export function isEmptyArray<T>(values: T[], depth: number = 0): boolean {
	if (isBlankValue(values)) {
		return true;
	}

	for (const item of values) {
		const notEmpty = isNotEmpty(item, depth + 1);
		if (notEmpty) {
			return false;
		}
	}

	return true;
}

export function isEmpty(value: unknown, depth: number = 0): boolean {
	// Limit how deep we inspect objects
	if (depth > 20) {
		console.warn('Max object depth exceeded, treating object as not empty');
		return false;
	}

	// For strings, trim before checking if they're empty
	if (isBlankValue(value)) {
		return true;
	}

	// Don't consider functions as empty
	if (typeof value === 'function') {
		return false;
	}

	// Empty array
	if (Array.isArray(value)) {
		return isEmptyArray(value, depth + 1);
	}

	// Empty object
	if (value instanceof Object) {
		return isEmptyObject(value, depth + 1);
	}

	return false;
}

export function isNotEmpty(value: unknown, depth: number = 0): boolean {
	return !isEmpty(value, depth);
}
