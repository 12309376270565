import { BrowserAuthError, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import {
	IMsalContext,
	MsalAuthenticationResult,
	MsalAuthenticationTemplate,
	useAccount,
	useMsal,
} from '@azure/msal-react';
import { PropsWithChildren, ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getLoginMethod, getRedirectRequest } from '../../common/util/employeeAuthConfig';
import { logError } from '../../common/util/logging/writeLog';
import { useAppInsights } from '../../components/AppInsightsWrapper/AppInsightsWrapper';
import { Spinner } from '../../components/Spinner/Spinner';
import { singletonFetchAuthenticationResult } from './useAuthenticationResult';

const LoginError = (error: MsalAuthenticationResult): ReactNode => {
	const appInsights = useAppInsights();
	const { instance } = useMsal();
	const account = useAccount();

	logError(appInsights, 'Login Error', { authenticationResult: error });

	let message = 'Error signing in';

	if (error.error?.message?.includes('AADSTS50105')) {
		message = 'Your account does not have access to this application.';
	}

	const loginMethod = getLoginMethod();
	if (error instanceof InteractionRequiredAuthError && loginMethod === InteractionType.Popup) {
		message =
			'Your browser may have blocked a pop-up required for login.  Please disable pop-up blocking for this site and refresh.';
	}

	// If we got an error due to browser issues, try forcing a manual redirect
	if (error instanceof BrowserAuthError) {
		// Need to trigger redirect
		void singletonFetchAuthenticationResult(appInsights, instance, account, true, true);
		return null;
	}

	return (
		<Container className="bodyContent">
			<Row className="error p-3">
				<Col>{message}</Col>
			</Row>
		</Container>
	);
};

const LoginInProgress = (_props: IMsalContext): ReactNode => {
	// TODO: does this need more?
	return <Spinner />;
};

export const AuthenticatedWrapper = (props: PropsWithChildren): ReactNode => {
	const loginMethod = getLoginMethod();

	return (
		<MsalAuthenticationTemplate
			interactionType={loginMethod}
			authenticationRequest={getRedirectRequest()}
			errorComponent={LoginError}
			loadingComponent={LoginInProgress}
		>
			{props.children}
		</MsalAuthenticationTemplate>
	);
};
