import { normalizeValue } from '../../strings/normalizeValue';

export function getURL(url: URL | string): URL {
	// Already a URL
	if (url instanceof URL) {
		return url;
	}

	return getURLFromString(url);
}
export function getURLFromString(url: string): URL {
	const normalized = normalizeValue(url);
	if (!normalized) {
		return undefined;
	}

	return new URL(normalized);
}
