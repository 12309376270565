'use client';

import { PropsWithChildren, ReactNode, RefObject, useState } from 'react';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { OverlayDelay } from 'react-bootstrap/esm/OverlayTrigger';
import styles from './detailsModal.module.scss';

interface PropsWithSupplementalContent extends PropsWithChildren {
	popup?: ReactNode;
	modal?: ReactNode;
	containerRef?: RefObject<HTMLElement>;
	delay?: OverlayDelay;
	defaultShow?: boolean;
}

export function ContentWithPopup(props: PropsWithSupplementalContent): ReactNode {
	const popup = props.popup;
	const containerRef = props.containerRef;

	const renderTooltip = (tooltipProps: object) => {
		return <Popover {...tooltipProps}>{popup}</Popover>;
	};

	let content = props.children;

	if (popup) {
		content = (
			<OverlayTrigger
				placement="top-end"
				flip={true}
				overlay={renderTooltip}
				delay={props.delay || { show: 200, hide: 100 }}
				container={containerRef?.current}
				defaultShow={props.defaultShow}
			>
				<div>{props.children}</div>
			</OverlayTrigger>
		);
	}

	return <>{content}</>;
}

interface ModalProps extends PropsWithChildren {
	showModal: boolean;
	setShowModal: (boolean) => void;
}

export const ContentModal = (props: ModalProps): ReactNode => {
	const handleClose = () => {
		props.setShowModal(false);
	};

	return (
		<Modal className={styles.modal} show={props.showModal} onHide={handleClose} centered={true}>
			{props.children}
		</Modal>
	);
};

export function TableRowWithModal(props: PropsWithSupplementalContent): ReactNode {
	const modal = props.modal;

	const [showModal, setShowModal] = useState(false);

	// TODO add modal

	let onClick: () => void;
	if (modal) {
		onClick = () => {
			// Toggle showing the modal
			setShowModal(!showModal);
		};
	}

	const classNames: string[] = [];
	let modalContent = null;
	if (modal) {
		classNames.push('clickable');
		modalContent = (
			<ContentModal showModal={showModal} setShowModal={setShowModal}>
				{modal}
			</ContentModal>
		);
	}

	return (
		<>
			<tr className={classNames.join(' ')} onClick={onClick}>
				{props.children}
			</tr>
			{modalContent}
		</>
	);
}

export function DivWithModal(props: PropsWithSupplementalContent): ReactNode {
	const modal = props.modal;

	const [showModal, setShowModal] = useState(false);

	// TODO add modal

	let onClick: () => void;
	if (modal) {
		onClick = () => {
			// Toggle showing the modal
			setShowModal(!showModal);
		};
	}

	const classNames: string[] = [];
	let modalContent = null;
	if (modal) {
		modalContent = (
			<ContentModal showModal={showModal} setShowModal={setShowModal}>
				{modal}
			</ContentModal>
		);
	}

	return (
		<>
			<div className={classNames.join(' ')}>
				<button type="button" className={styles.button} onClick={onClick}>
					{props.children}
				</button>
			</div>
			{modalContent}
		</>
	);
}
