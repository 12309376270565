import { isBefore } from 'date-fns';
import { JwtPayload, jwtDecode } from 'jwt-decode';

export function getJwtToken(value: string): JwtPayload {
	try {
		// Get the expiration from the token
		return jwtDecode<JwtPayload>(value);
	} catch {
		// Token was invalid
		return undefined;
	}
}

export function getTokenUser(value: string): string {
	const token = getJwtToken(value);
	return token?.sub;
}

export function getTokenExpiration(value: string): Date {
	// TODO: we should be looking at the MSAL refreshOn attribute from the authentication request

	const token = getJwtToken(value);
	try {
		// Get the expiration from the token
		const expiration = token?.exp;
		return new Date(expiration * 1000);
	} catch {
		// Token was invalid
		return undefined;
	}
}

// TODO: incorporate this into making requests
function isGoodToken(value: string): boolean {
	if (!value) {
		return false;
	}

	try {
		// Get the expiration from the token
		const tokenDate = getTokenExpiration(value);

		// Token is expired
		const expired = isBefore(tokenDate, new Date());
		if (expired) {
			return false;
		}
	} catch {
		// Not a valid token
		return false;
	}

	// If we get here, the token is good
	return true;
}

export function getGoodToken(value: string): string {
	const goodToken = isGoodToken(value);

	// If it's not good, fetch a new one
	if (!goodToken) {
		return undefined;
	}

	// If it's good, return the value
	return value;
}
