import { equalJson } from '@dte/otw/azure-functions/src/common/util/objects/equalJson';
import { useCallback, useState } from 'react';

export function useJsonEqualState<T>(initialValue?: T): [T, (value: T) => void] {
	// Track changes in the input
	const [value, setValue] = useState<T>(initialValue);

	// Ensure that the output remains stable
	const setValueIfDifferent = useCallback(
		(newValue: T) => {
			try {
				if (equalJson(value, newValue)) {
					return;
				}
			} catch (error) {
				console.warn('Error comparing JSON values: ', error);

				// If we fail to compare, fall back on basic equality
				if (value === newValue) {
					return;
				}
			}

			setValue(newValue);
		},
		[value],
	);

	return [value, setValueIfDifferent];
}
