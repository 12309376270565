'use client';

import { isTruthy } from '@dte/otw/azure-functions/src/common/util/strings/isTruthy';
import {
	AppInsightsContext,
	ReactPlugin,
	useAppInsightsContext,
	withAITracking,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, IConfig, IConfiguration, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { PropsWithChildren } from 'react';
import { environmentConfig } from '../../common/util/environment';

const connectionStrings: Record<string, string> = {
	Production:
		'InstrumentationKey=bb5b97dd-f3f5-4f83-be86-4af1972e54e6;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/',
	QA: 'InstrumentationKey=c42a76bc-8aa3-4ddc-8fed-87a2b3cde566;IngestionEndpoint=https://eastus2-4.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/',
	UAT: 'InstrumentationKey=90d8d806-8d93-4d4d-93bb-7de96d7ea70e;IngestionEndpoint=https://eastus2-0.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/',
	DEV: 'InstrumentationKey=2067a4a8-4b23-473b-8a30-cf446ad17116;IngestionEndpoint=https://eastus2-4.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/',
};

function getConnectionString() {
	// Production
	if (environmentConfig.isProd) {
		return connectionStrings.Production;
	}

	if (environmentConfig.isUAT) {
		return connectionStrings.UAT;
	}

	if (environmentConfig.isQA) {
		return connectionStrings.QA;
	}

	// Dev
	return connectionStrings.DEV;
}

const connectionString = getConnectionString();
const reactPlugin = new ReactPlugin();

const config: IConfiguration & IConfig = {
	connectionString: connectionString,
	enableAutoRouteTracking: true,
	enableAjaxErrorStatusText: true,

	// Monitor all AJAX calls
	maxAjaxCallsPerView: -1,

	extensions: [reactPlugin],
};

if (process.env.NODE_ENV === 'development') {
	// Toggle telemetry export as needed
	const enableLocalTelemetry = isTruthy(process.env.NEXT_ENABLE_APP_INSIGHTS);
	if (enableLocalTelemetry) {
		console.log('App Insights Telemetry is enabled');
	} else {
		console.log('App Insights Telemetry is disabled');
	}
	config.disableTelemetry = !enableLocalTelemetry;
}

const appInsights = new ApplicationInsights({
	config: config,
});

function filterSuccessfulArcgisCalls(envelope: ITelemetryItem): boolean {
	const telemetryItem = envelope.data.baseData;

	// Log failures
	if (!telemetryItem.success) {
		return true;
	}

	if (telemetryItem?.url) {
		const url = new URL(telemetryItem.url);
		const domain = url.hostname;

		// Filter out successful requests to arcgis.com
		if (domain?.includes('arcgis.com')) {
			return false; // Filter out this telemetry item
		}
	}
	return true; // Allow all other telemetry items
}
appInsights.addTelemetryInitializer(filterSuccessfulArcgisCalls);

appInsights.loadAppInsights();

// Wrapper in case we decide to allow other methods for accessing app insights
export function useAppInsights() {
	return useAppInsightsContext();
}

function BaseAppInsightsComponent(props: PropsWithChildren) {
	return <AppInsightsContext.Provider value={reactPlugin}>{props.children}</AppInsightsContext.Provider>;
}

// TODO: may need to wrap other components as well?
// To instrument various React components usage tracking, apply the `withAITracking` higher-order
// component function.
const AppInsightsWrapper = withAITracking(reactPlugin, BaseAppInsightsComponent);

export default AppInsightsWrapper;
