export function normalizeValue<T extends string>(value: T): T {
	// Cover if value is null, undefined, or not a string
	if (typeof value?.trim !== 'function') {
		return undefined;
	}

	const trimmed = value.trim();
	if (!trimmed) {
		return undefined;
	}

	return trimmed as T;
}
