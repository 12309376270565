import { NavigationClient } from '@azure/msal-browser';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

/**
 * Adapted from sample at:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/nextjs-sample/src/utils/NavigationClient.js
 *
 * (Default Sample was based on the old Next.js pages router)
 */

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
	private router: AppRouterInstance;

	constructor(router: AppRouterInstance) {
		super();
		this.router = router;
	}

	/**
	 * Navigates to other pages within the same web application
	 * You can use the useRouter hook provided by next.js to take advantage of client-side routing
	 * @param url
	 * @param options
	 */
	// Marking as async because the interface we're extending requires it
	// eslint-disable-next-line @typescript-eslint/require-await
	async navigateInternal(url, options): Promise<boolean> {
		const relativePath = url.replace(window.location.origin, '');

		if (options.noHistory) {
			this.router.replace(relativePath);
		} else {
			this.router.push(relativePath);
		}

		return false;
	}
}
