'use client';

import { useEffect, useRef, useState } from 'react';

export function useDebouncedValue<T>(inputValue: T, delay = 100): T {
	const [debouncedValue, setDebouncedValue] = useState(inputValue);

	const timeoutRef = useRef(null);

	useEffect(() => {
		// Clear the previous timeout when the input value changes
		clearTimeout(timeoutRef.current);

		// Create a new timeout
		timeoutRef.current = setTimeout(() => {
			setDebouncedValue(inputValue);
		}, delay);

		return () => {
			// Cleanup function: clear the timeout when the component unmounts or the input value changes
			clearTimeout(timeoutRef.current);
		};
	}, [inputValue, delay]);

	return debouncedValue;
}
