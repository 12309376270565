export function percentage(value: number, max: number): number {
	if (!(value > 0)) {
		return 0;
	}

	if (!(max > 0)) {
		return 0;
	}

	if (value > max) {
		return 100;
	}

	return 100 * (value / max);
}
