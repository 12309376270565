import { SearchResults } from '@dte/otw/azure-functions/src/common/interfaces/admin/search/SearchResults';
import { PropsWithChildren, useMemo } from 'react';
import { useAuthenticatedJsonGet } from '../../../../../common/util/services/useAuthenticatedJsonGet';
import { getSearchURL } from '../../../../../components/header/search/getSearchURL';
import { useValidatedStringQueryParam } from '../../../../../hooks/parameters/useValidatedStringQueryParam';

function getValue(props: PropsWithJobId, queryValue: string) {
	// Check first if we had the premise id passed in as a parameter
	const value = props?.jobId;
	if (value !== undefined) {
		return value;
	}

	// Fall back on looking at the URL
	return queryValue;
}

export interface PropsWithJobId extends PropsWithChildren {
	jobId: string;
}

function useSearchDisplayId(queryValue: string) {
	const url = useMemo(() => {
		return getSearchURL(queryValue);
	}, [queryValue]);

	const [searchResults] = useAuthenticatedJsonGet<SearchResults>(url);
	const searchJobId = useMemo(() => {
		return searchResults?.jobs?.[0]?.jobId;
	}, [searchResults]);

	return searchJobId;
}

function useJobIdFromQuery() {
	const queryValue = useValidatedStringQueryParam('jobId');

	let possibleDisplayId: string;
	if (queryValue?.length === 13) {
		possibleDisplayId = queryValue;
	}
	const searchJobId = useSearchDisplayId(possibleDisplayId);

	if (possibleDisplayId) {
		if (searchJobId) {
			return searchJobId;
		}
		return undefined;
	}

	return queryValue;
}

export function useJobId(props?: PropsWithJobId) {
	const queryValue = useJobIdFromQuery();

	getSearchURL(queryValue);

	return getValue(props, queryValue);
}
