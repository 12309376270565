import { v4 } from 'uuid';

// Features within the app
export enum AppAction {
	SUMMARY = 'summary',
	TIMELINE = 'timeline',
	SEARCH_ORDERS = 'orders',

	REPORT_PROBLEM = 'reportProblem',

	SWITCH_MODE = 'switchMode',
	EDIT_MODES = 'editModes',
	EDIT_COUNTS = 'editCounts',
	EDIT_SETTINGS = 'editSettings',

	// Any config action
	CONFIG = 'config',

	DEBUG = 'debug',
}

// Roles that are allowed to access each feature
const AllowedRoles: Record<string, string[]> = {};
AllowedRoles[AppAction.SUMMARY] = ['otw.admin', 'summary.read'];
AllowedRoles[AppAction.TIMELINE] = ['otw.admin', 'history.read'];

// Add this to any roles for testing as needed
const AnyRole = v4();

AllowedRoles[AppAction.REPORT_PROBLEM] = ['otw.admin', 'orders.write'];
AllowedRoles[AppAction.SEARCH_ORDERS] = ['otw.admin', 'orders.write', 'orders.read'];

AllowedRoles[AppAction.SWITCH_MODE] = ['otw.admin', 'config.write', 'modes.write'];
AllowedRoles[AppAction.EDIT_MODES] = ['otw.admin', 'config.write', 'messages.write'];
AllowedRoles[AppAction.EDIT_COUNTS] = ['otw.admin', 'counts.write'];
AllowedRoles[AppAction.EDIT_SETTINGS] = ['otw.admin'];

// Can access config if you can do any of the other editing type activities
AllowedRoles[AppAction.CONFIG] = [
	...AllowedRoles[AppAction.SWITCH_MODE],
	...AllowedRoles[AppAction.EDIT_MODES],
	...AllowedRoles[AppAction.EDIT_COUNTS],
	...AllowedRoles[AppAction.EDIT_SETTINGS],
];

AllowedRoles[AppAction.DEBUG] = ['otw.admin', 'history.read'];

function hasRole(userRoles: string[], allowedRoles: string[]): boolean {
	const roles = userRoles || [];

	for (const role of allowedRoles) {
		// Use this if we want to allow any role to do something
		if (role === AnyRole) {
			return true;
		}

		if (roles.includes(role)) {
			return true;
		}
	}

	return false;
}

export function isAllowed(action: AppAction, roles: string[]): boolean {
	// Assume true if no action is passed in
	if (!action) {
		return true;
	}

	return hasRole(roles, AllowedRoles[action]);
}
