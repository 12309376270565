import { getStableJson } from './getStableJson';

export function stringifyJSON(value: unknown): string {
	try {
		const jsonString = JSON.stringify(value, getStableJson, '\t');
		return jsonString;
	} catch {
		return undefined;
	}
}
