import { normalizeValue } from './normalizeValue';

export function upperCaseValue(value: string): string {
	const trimmed = normalizeValue(value);
	if (!trimmed) {
		return undefined;
	}

	return trimmed.toLocaleUpperCase('en-US');
}
