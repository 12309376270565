import { stringifyJSON } from './stringifyJSON';

export function equalJson<T = unknown>(valueA: T, valueB: T): boolean {
	// Shortcut and return true if the values are strictly equal
	if (valueA === valueB) {
		return true;
	}

	return stringifyJSON(valueA) === stringifyJSON(valueB);
}
