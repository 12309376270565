import { Atom, WritableAtom, useSetAtom } from 'jotai';
import { PartialDeep } from 'type-fest';

export function useSetAtomWithType<AtomType, ValueType = AtomType>(
	atom: Atom<AtomType> | WritableAtom<AtomType, unknown[], unknown>,
): (value: ValueType | PartialDeep<ValueType>) => void {
	// Use the same transformation as useAtom
	const setter = useSetAtom(atom as WritableAtom<AtomType, unknown[], unknown>);
	return setter;
}
