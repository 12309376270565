import { isBlankValue } from './isBlankValue';

export function ignoreBlankValues(_key: string, value: unknown): unknown {
	// Normalize strings
	if (isBlankValue(value)) {
		return undefined;
	}

	return value;
}
