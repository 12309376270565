'use client';

import { FullStory, init } from '@fullstory/browser';
import { FSApi } from '@fullstory/snippet';
import { atom } from 'jotai';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { useSetAtomWithType } from '../../hooks/useSetAtomWithType';
import NoSSR from '../NoSSR';
import { getFullStoryOptions } from './fullStoryConfig';

export interface ReadyResponse {
	sessionUrl: string;
	settings: Readonly<object>;
}

const fullStoryAtom = atom<FSApi>(undefined);

function FullStoryInit() {
	const options = getFullStoryOptions();
	const setFullStory = useSetAtomWithType(fullStoryAtom);

	const initialized = useRef(false);

	useEffect(() => {
		// Wait for options
		if (!options) {
			return;
		}

		// Ensure we only initialized once
		if (initialized.current) {
			return;
		}
		initialized.current = true;

		// TODO: this doesn't seem to be working
		const readyCallback = (data: ReadyResponse) => {
			console.log('Full story is ready: ', data);
		};

		init(options, readyCallback);
		setFullStory(FullStory);
	}, [setFullStory, options]);

	return null;
}

export function FullStoryWrapper(props: PropsWithChildren) {
	// const options = getFullStoryOptions();
	// const setFullStory = useSetAtomWithType(fullStoryAtom);

	// const initialized = useRef(false);
	// const ready = useRef(false);
	// const readyResponse = useRef<ReadyResponse>(undefined);

	// const [api, setApi2] = useState<FSApi>(undefined);

	// useEffect(() => {
	//     // Need options to initialize Full Story
	//     if (!options) {
	//         console.log('Full Story: No options to initialize');
	//         return;
	//     }

	//     // Ensure we only initialized once
	//     if (initialized.current) {
	//         return;
	//     }
	//     initialized.current = true;

	//     // TODO: this doesn't seem to be working
	//     const readyCallback = (data: ReadyResponse) => {
	//         console.log('Full story is ready, setting api to: ', FullStory);
	//         ready.current = true;
	//         readyResponse.current = data;
	//         // setApi(FullStory);
	//     };

	//     init(options, readyCallback);

	//     setFullStory(FullStory);
	//     // setFullStory(FullStory);
	// }, [options, setFullStory]);

	// console.log('full story context being set to: ', api);

	return (
		<>
			<NoSSR>
				<FullStoryInit />
			</NoSSR>
			{props.children}
		</>
	);
}

export function useFullStory(): FSApi {
	return undefined;
	// const fullStory = useAtomValue(fullStoryAtom);

	// TODO: add a shim to queue things up if requests are made before the API is ready

	// return fullStory;
	// const fullStory = useFullStoryContext();

	// return fullStory;
}
