import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/.pnpm/jotai@2.11.1_@types+react@18.3.18_react@18.3.1/node_modules/jotai/esm/react.mjs");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/.pnpm/next@15.1.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.83.4/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/vsts/work/1/s/node_modules/.pnpm/nextjs-toploader@3.7.15_next@15.1.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18._4c005f75f7ddc055041fd14fdf4fcd94/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LocalHostRedirect"] */ "/home/vsts/work/1/s/packages/app/src/app/LocalHostRedirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/packages/app/src/components/jotai/DevOnlyJotaiDebugging.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/packages/app/src/components/styles/GlobalStyles.tsx");
