import { SnippetOptions } from '@fullstory/browser';
import { environmentConfig } from '../../common/util/environment';

const orgIds: Record<string, string> = {
	Production: 'SZ9NP',
	QA: 'QCMR5',
};

const fullStoryOptions: Record<string, SnippetOptions> = {
	Production: {
		orgId: orgIds.Production,
	},
	QA: {
		orgId: orgIds.QA,
	},
	Dev: {
		orgId: orgIds.QA,
		devMode: true,
	},
	Local: {
		orgId: orgIds.QA,
		// devMode: true,
		debug: true,
	},
};

export function getFullStoryOptions(): SnippetOptions {
	if (environmentConfig.isProd) {
		return fullStoryOptions.Production;
	}

	if (environmentConfig.isQA) {
		return fullStoryOptions.QA;
	}

	if (environmentConfig.isDev) {
		return fullStoryOptions.Dev;
	}

	if (process.env.NODE_ENV === 'development') {
		return fullStoryOptions.Local;
	}

	return undefined;
}
