import { useMemo } from 'react';
import { useAuthenticationResult } from './useAuthenticationResult';

export interface ClaimWithRoles {
	roles?: string[];
}

export function useRoles(): string[] {
	const [result] = useAuthenticationResult();
	const claims = result?.idTokenClaims as ClaimWithRoles;

	const roles = useMemo(() => {
		if (claims === undefined) {
			return undefined;
		}

		return claims.roles ?? [];
	}, [claims]);

	return roles;
}
