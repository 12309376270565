import { isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { replaceTokens } from '../../strings/replaceTokens';

// Months in AP Style
const monthSubstitutions: Record<string, string> = {
	January: 'Jan.',
	February: 'Feb.',
	August: 'Aug.',
	September: 'Sept.',
	October: 'Oct.',
	November: 'Nov.',
	December: 'Dec.',
};

function includesShortMonth(dateFormat: string): boolean {
	return dateFormat.includes('MMM') && !dateFormat.includes('MMMM');
}

function abberviateMonths(dateString: string): string {
	return replaceTokens(dateString, monthSubstitutions);
}

export function formatEST(date: Date, dateFormat: string): string {
	if (!isValid(date)) {
		return undefined;
	}

	let formatToUse = dateFormat;

	// Determine if we need custom logic to replace short months
	const shortMonth = includesShortMonth(formatToUse);

	// If using short months and the date format contains 'MMMM', start by formatting the date with the full month name (then do a replacement with the abbreviation afterwards)
	if (shortMonth) {
		formatToUse = formatToUse.replace('MMM', 'MMMM');
	}

	let formatted: string;
	try {
		formatted = formatInTimeZone(date, 'America/New_York', formatToUse);

		// Go back and replace the full month name with the abbreviated version
		if (shortMonth) {
			formatted = abberviateMonths(formatted);
		}
	} catch {
		// Unable to format date
	}

	return formatted;
}
