import { PremiseDataAddress } from '../../../../interfaces/containers/premises/PremiseData';
import { safeJoin } from '../../../../util/strings/safeJoin';
import { titleCaseValue } from '../../../../util/strings/titleCaseValue';

export function getAddressLine2(address: PremiseDataAddress): string {
	const parts = [address.secondaryCode, address.secondaryValue];

	let joined = safeJoin(parts);
	joined = titleCaseValue(joined);

	return joined;
}
