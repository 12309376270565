'use client';

import { redirect } from 'next/navigation';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { environmentConfig } from '../../../common/util/environment';
import { AuthenticatedWrapper } from '../../../contexts/TokenContext/AuthenticatedEmployeeWrapper';

export default function AuthenticatedLayout({ children }: PropsWithChildren): ReactNode {
	// Redirect to the debug page if we don't have a subscription key
	useEffect(() => {
		if (!environmentConfig.isProd && !environmentConfig.apiSubscriptionKey) {
			redirect('/debug/test?invalidKey=true');
		}
	}, []);

	return <AuthenticatedWrapper>{children}</AuthenticatedWrapper>;
}
