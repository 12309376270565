import { useEffect, useRef } from 'react';

export function usePrevious<T>(value?: T, clearOnUnmount: boolean = false): T {
	const ref = useRef(value);

	useEffect(() => {
		ref.current = undefined;

		return () => {
			if (clearOnUnmount) {
				ref.current = undefined;
			}
		};
	}, [clearOnUnmount]);

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
}
