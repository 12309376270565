'use client';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useRouter } from 'next/navigation';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { getMsalConfig } from '../../common/util/employeeAuthConfig';
import { logException } from '../../common/util/logging/writeLog';
import { useAppInsights } from '../../components/AppInsightsWrapper/AppInsightsWrapper';
import { Spinner } from '../../components/Spinner/Spinner';
import { CustomNavigationClient } from './NavigationClient';

export function MsalWrapper(props: PropsWithChildren): ReactNode {
	const [instance, setInstance] = useState<PublicClientApplication>();
	const router = useRouter();
	const appInsights = useAppInsights();

	useEffect(() => {
		if (!router) {
			return;
		}
		if (!appInsights) {
			return;
		}

		const msalConfig = getMsalConfig(appInsights);
		const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);
		const navigationClient = new CustomNavigationClient(router);
		msalInstance.setNavigationClient(navigationClient);

		const msalInstancePromise = msalInstance.initialize();
		msalInstancePromise
			.then(() => {
				setInstance(msalInstance);
			})
			.catch((error) => {
				logException(appInsights, error);
				throw new Error('Unable to initialize authentication client');
			});
	}, [router, appInsights]);

	if (!instance) {
		return <Spinner />;
	}

	return <MsalProvider instance={instance}>{props.children}</MsalProvider>;
}
