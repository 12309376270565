import { useMemo } from 'react';
import { usePrevious } from './usePrevious';

type EqualityTest<T = unknown> = (a: T, b: T) => boolean;

const basicEquality: EqualityTest = (a, b) => {
	return a === b;
};

export function useHasChanged<T>(value?: T, equalityTest = basicEquality): boolean {
	const previous = usePrevious(value);

	const changed = useMemo(() => {
		return !equalityTest(value, previous);
	}, [value, previous, equalityTest]);

	return changed;
}
