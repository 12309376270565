'use client';

import { emptyStringOrDefault } from '@dte/otw/azure-functions/src/common/util/strings/emptyStringOrDefault';
import { redirect } from 'next/navigation';
import { useEffect } from 'react';
import NoSSR from '../components/NoSSR';

function isLocalhost(): boolean {
	if (typeof window === 'undefined') {
		return false;
	}
	const hostname = emptyStringOrDefault(window?.location?.hostname);
	return hostname.indexOf('localhost') !== -1 || hostname.indexOf('127.0.0.1') !== -1;
}

// For local development, redirect to development hostname
function RedirectComponent() {
	// Redirect if we're on localhost
	useEffect(() => {
		// Only check for localhost in development mode
		if (process.env.NODE_ENV !== 'development') {
			return;
		}

		if (isLocalhost()) {
			redirect('https://dev.outageadmin.sites.dteenergy.com/');
		}
	}, []);

	return null;
}

export function LocalHostRedirect() {
	return (
		<NoSSR>
			<RedirectComponent />
		</NoSSR>
	);
}
