'use client';

export function useHasData(data?: unknown, hasData?: boolean) {
	// We have a flag for has data manually passed in
	if (hasData !== undefined) {
		return hasData;
	}

	return data !== undefined;
}
