import { useAccount, useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { useAppInsights } from '../../components/AppInsightsWrapper/AppInsightsWrapper';
import { fetchToken } from './useAuthenticationResult';

export function useGetToken(): () => Promise<string> {
	const appInsights = useAppInsights();
	const { accounts, instance } = useMsal();
	const account = useAccount(accounts?.[0] || null);

	const getToken = useCallback(async () => {
		return fetchToken(appInsights, instance, account);
	}, [instance, account, appInsights]);

	return getToken;
}
